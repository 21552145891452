<template>
  <div>
    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <!-- <div class="mt-8">
            <vs-input class="w-full" v-validate="'required|alpha_num'" label-placeholder="ยูสเซอร์เนม"
              v-model="username" name="username" />
            <span class="text-danger text-sm" v-show="errors.has('username')">{{ errors.first('username') }}</span>
          </div> -->
          <div class="mt-8">
            <vs-input class="w-full" v-validate="'required|decimal'" label-placeholder="จำนวนเงิน" v-model="amount"
              name="amount" />
            <span class="text-danger text-sm" v-show="errors.has('amount')">{{ errors.first('amount') }}</span>
          </div>
          <div class="mt-8">
            <vs-textarea class="w-full" v-validate="'required'" label="หมายเหตุ" v-model="info" name="info" />
            <span class="text-danger text-sm" v-show="errors.has('info')">{{ errors.first('info') }}</span>
          </div>

          <!-- bank -->
          <div class="mt-4">
            <label class="vs-input--label">ธนาคาร</label>
            <vs-select class="w-full" v-model="banktype">
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in options_bank"
              />
            </vs-select>
          </div>

          <!-- name bank -->
          <div class="mt-8">
            <label class="vs-input--label">เลือกชื่อบัญชี</label>
            <vs-select class="w-full mt-4" v-model="bank_id">
              <vs-select-item
                :key="index"
                :value="item.bank_id"
                :text="item.bank_fullname"
                v-for="(item, index) in option_bank_account"
              />
            </vs-select>
          </div>
          <br>
          <label> อัพสลิปธนาคาร COPY & PASTE </label>
          <div class="mt-2">
          <!-- <vs-input class="inputx" placeholder="กรุณาวางรูป"/> -->
          <vs-input class="inputx" placeholder="กรุณาวางรูป" :value="selectedFile.name" @paste="onPaste"/>
          <vs-checkbox disabled="true" class="mt-2" :color="chk_box.color" v-model="chk_box.data">{{chk_box.text}}</vs-checkbox>
          <canvas  style="border:1px solid grey;" id="mycanvas" width="100" height="100" ></canvas>
          </div>

          <vs-button color="success" type="relief" class="mt-8" @click="submit_credit">เติมเงิน</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios'
import * as imageConversion from 'image-conversion'
import { Validator } from 'vee-validate'
const dict = {
  custom: {
    amount: {
      required: 'กรุณากรอกข้อมูล',
      decimal: 'ต้องเป็นตัวเลขหรือจุดทศนิยมเท่านั้น'
    },
    info: {
      required: 'กรุณากรอกข้อมูล'
    }
  }
}
Validator.localize('en', dict)


export default {
  data () {
    return {
      bankfor: 'กรุณาเลือกบัญชีที่ลูกค้าโอน',
      bank_id: '',
      amount: '',
      info: '',
      time: 0,
      banktype: '',
      adjustbalance_error: {},
      // onPaste: [],
      selectedFile: [],
      bankbay: [],
      bankgsb: [],
      bankktb: [],
      bankscb_data: [],
      bankkkp: [],
      chk_box: { text: 'กรุณาวางรูป', color: 'danger', data: false },
      options_bank: [
        {
          text: 'ไทยพาณิชย์',
          value: 'SCB'
        },
        {
          text: 'ออมสิน',
          value: 'GSB'
        },
        {
          text: 'กรุงศรี',
          value: 'BAY'
        },
        {
          text: 'กรุงไทย',
          value: 'KTB'
        },
        {
          text: 'เกียรตินาคิน',
          value: 'KKP'
        }
      ],
      option_bank_account: []
    }
  },
  async mounted () {
    await axios
      .get('settingwallet/banktransfer/scb')
      .then(response => (this.bankscb_data = response.data))
    await axios
      .get('settingwallet/banktransfer/bay')
      .then(response => (this.bankbay = response.data))
    await axios
      .get('settingwallet/banktransfer/gsb')
      .then(response => (this.bankgsb = response.data))
    await axios
      .get('settingwallet/banktransfer/ktb')
      .then(response => (this.bankktb = response.data))
    await axios
      .get('settingwallet/banktransfer/kkp')
      .then(response => (this.bankkkp = response.data))

  },
  methods: {
    async submit_credit () {
      this.$validator.validateAll().then(async result => {
        if (result) {
          if (this.amount > 0 && this.amount !== 0) {
            await axios.post('/bankwithdraw/adjustbalance', {
              bank_id: this.bank_id,
              balance: this.amount,
              info: this.info,
              bank: this.banktype
            }).then((response) => (this.adjustbalance_error = response.data))

            if (this.adjustbalance_error.status === true) {
              if (this.chk_box.data === true) {
                await this.onUploadFile()
              }
              await this.$vs.notify({
                time: 10000,
                color: 'success',
                position: 'top-right',
                icon: 'error',
                title: this.adjustbalance_error.title,
                text: this.adjustbalance_error.info
              })
              setTimeout(() => {
                window.location.reload()
              }, 1500)

            } else {
              this.$vs.notify({
                time: 10000,
                color: 'danger',
                position: 'top-right',
                icon: 'error',
                title: this.adjustbalance_error.title,
                text: this.adjustbalance_error.info
              })
            }

          } else {
            this.$vs.notify({
              time: 3000,
              color: 'danger',
              position: 'top-right',
              icon: 'error',
              title: 'ทำรายการไม่สำเร็จ',
              text: 'จำนวนเงินต้องมากกว่า 0 เท่านั้น'
            })
          }
          // if (this.adjustbalance_error.status === true) {
          //   await axios.post('/line/agent', {
          //     username: this.username,
          //     balance: this.amount,
          //     info: this.info,
          //     admin:this.$store.state.AppActiveUser.username
          //   })
          // }

        } else {
          this.$vs.notify({
            time: 3000,
            color: 'danger',
            position: 'top-right',
            icon: 'error',
            title: 'ทำรายการไม่สำเร็จ',
            text: 'กรุณากรอกข้อมูลให้ครบ'
          })

        }


      })
    },
    async compressFile () {
      const res = await imageConversion.compress(this.selectedFile, {
        size: 100,
        quality: 0.5,
        type: 'image/jpg'
        // width: 300,
        // height: 200,
        // scale: 0.5
      })
      this.selectedFile = res
    },
    async onUploadFile () {
      await this.compressFile()
      const formData = new FormData()
      if (this.amount < 0) { formData.append('path', 'withdraw') }
      formData.append('file', this.selectedFile)
      formData.append('topupid', this.adjustbalance_error.data.id) // appending file

      // sending file to the backend
      await axios
        .post('aws3/upload/upload', formData)
        .then()
        // .catch(err => { console.log('errja', err) })

    },
    retrieveImageFromClipboardAsBlob (pasteEvent, callback) {
      if (pasteEvent.clipboardData === false) {
        if (typeof callback === 'function') {
          callback(undefined)
        }
      }

      const items = pasteEvent.clipboardData.items

      if (items === undefined) {
        if (typeof callback === 'function') {
          callback(undefined)
        }
      }

      for (let i = 0; i < items.length; i++) {
      // Skip content if not image
        if (items[i].type.indexOf('image') === -1) continue
        // Retrieve image on clipboard as blob
        const blob = items[i].getAsFile()

        if (typeof callback === 'function') {
          callback(blob)
          this.selectedFile = blob
        }
      }
    },
    onPaste (evt) {
    // console.log('on paste', evt)
    // Handle the event
      this.retrieveImageFromClipboardAsBlob(evt, function (imageBlob) {
      // If there's an image, display it in the canvas
        if (imageBlob) {
          const canvas = document.getElementById('mycanvas')
          const ctx = canvas.getContext('2d')

          // Create an image to render the blob on the canvas
          const img = new Image()

          // Once the image loads, render the img on the canvas
          img.onload = function () {
            // Update dimensions of the canvas with the dimensions of the image


            // Draw the resized image
            const resizedWidth = 300 // Adjust the resized width as needed
            const resizedHeight = (img.height / img.width) * resizedWidth

            canvas.width = resizedWidth
            canvas.height = resizedHeight

            ctx.drawImage(img, 0, 0, resizedWidth, resizedHeight)
            // Draw the image


            // Display a smaller version of the image
            const thumbnailCanvas = document.getElementById('thumbnailCanvas')
            const thumbnailCtx = thumbnailCanvas.getContext('2d')

            const thumbnailSize = 100 // Adjust the size as needed
            thumbnailCanvas.width = thumbnailSize
            thumbnailCanvas.height = thumbnailSize
            thumbnailCtx.drawImage(img, 0, 0, img.width, img.height, 0, 0, thumbnailSize, thumbnailSize)
          }

          // Crossbrowser support for URL
          const URLObj = window.URL || window.webkitURL

          // Creates a DOMString containing a URL representing the object given in the parameter
          // namely the original Blob
          img.src = URLObj.createObjectURL(imageBlob)
        }
      })
    }
  },
  watch: {
    selectedFile () {
      if (this.selectedFile.name.length > 0) {
        this.chk_box.text = 'วางรูปสำเร็จ'
        this.chk_box.color = 'success'
        this.chk_box.data = true
      } else {
        this.chk_box.text = 'วางรูปไม่สำเร็จ'
        this.chk_box.color = 'danger'
        this.chk_box.data = false
      }
    },
    banktype (newValue) {
      if (newValue === 'SCB') {
        this.option_bank_account = this.bankscb_data
      }
      if (newValue === 'GSB') {
        this.option_bank_account = this.bankgsb
      }
      if (newValue === 'KTB') {
        this.option_bank_account = this.bankktb
      }
      if (newValue === 'BAY') {
        this.option_bank_account = this.bankbay
      }
      if (newValue === 'KKP') {
        this.option_bank_account = this.bankkkp
      }
    }
  }
}
</script>

<style>

</style>
